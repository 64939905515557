import React, { useEffect, useState } from "react"
import Layout from "../page-components/layout"
import './immoanfrage.scss';

import { graphql } from "gatsby"
import axios from "axios"
import { getStrapiUrl } from "../helper/uri"
import * as Yup from "yup"
import RcLoadingModal from "../page-components/modal/rcLoadingModal"
import { Formik } from "formik"
import { toast } from 'react-toastify';
import RcFormErrors from "../page-components/helper/rcFormErrors"
import RcButton from "../components/button/rcButton"
import RcDefaultPageTeaser from "../page-components/teaser/rcDefaultPageTeaser"

const ImmoanfragePage = ( { data } ) => {

  const [ sent, setSent ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const formSchema = Yup.object().shape(
    {
      ruecktrittAccept: Yup.boolean().required("Bitte akzeptieren Sie die Belehrung über Ihr Rücktrittsrecht"),
      nebenkostenAccept: Yup.boolean().required("Bitte akzeptieren Sie, dass Sie das Merkblatt über die Nebenkosten erhalten haben"),
      dsgvoAccept: Yup.string().required("Bitte akzeptieren Sie die Datenschutzerklärung")
    }
  );


  const [ inquiry, setInquiry ] = useState(null);
  const [ uuid, setUuid ] = useState(null);

  useEffect(
    () => {
      const action = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const uuid = urlParams.get("uuid");
        setUuid(uuid);

        try {
          setLoading(true);
          const res = await axios.get(getStrapiUrl() + "/immo-inquiry/" + uuid);
          setInquiry(res.data);
        } catch(err) {
          toast("Unerwarteter Fehler passiert!");
        } finally {
          setLoading(false);
        }
      };

      action();
    },
    []
  );

  return <Layout>
    <RcDefaultPageTeaser>
      <h1>Immobilien Anfrage</h1>
    </RcDefaultPageTeaser>

    <div className="container bg-white h-100">
      <div className="row">
        <div className="col-12 py-4">

          <h2>Zustimmung</h2>
          <p>Damit wir als Immobilien Makler für Sie tätig werden können, bitten wir Sie folgenden Dokumenten zuzustimmen:</p>


          <RcLoadingModal isOpen={loading} />


                {!sent &&

                <Formik
                  initialValues={
                    {
                      ruecktrittAccept: '',
                      nebenkostenAccept: '',
                      dsgvoAccept: ''
                    }
                  }

                  validationSchema={formSchema}

                  onSubmit={
                    async (values) => {
                      setLoading(true);

                      try {
                        await axios.post(getStrapiUrl() + "/immo-inquiry/" + uuid, values);
                        setSent(true);
                      } catch(err) {
                        toast("Es ist ein unbekannter Fehler passiert.");
                      } finally {
                        setLoading(false);
                      }

                    }
                  }
                >
                  {
                    ({
                       values,
                       handleChange,
                       handleBlur,
                       handleSubmit,

                       touched,
                       errors
                     }) => {
                      return <form className={"rc-form immoInquiryForm"}>

                        <div className="rc-form-control">

                          <input name="dsgvoAccept"
                                 id="dsgvoAccept"
                                 className={"mr-2"}
                                 type="checkbox"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.dsgvoAccept}/>

                          <label htmlFor="dsgvoAccept">
                            <a href={data.strapiImmoforms.dsgvoPDF.url} target={"_blank"}>
                              Zustimmung der Datenschutzerklärung
                            </a>
                          </label>

                        </div>

                        <div className="rc-form-control">

                          <input name="nebenkostenAccept"
                                 id="nebenkostenAccept"
                                 className={"mr-2"}
                                 type="checkbox"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.nebenkostenAccept}/>

                          <label htmlFor="nebenkostenAccept">
                            <a href={data.strapiImmoforms.nebenkostenPDF.url} target={"_blank"}>
                              Zustimmung der Nebenkosten Abrechnung
                            </a>
                          </label>

                        </div>

                        <div className="rc-form-control">

                          <input name="ruecktrittAccept"
                                 id="ruecktrittAccept"
                                 className={"mr-2"}
                                 type="checkbox"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 value={values.ruecktrittAccept}/>

                          <label htmlFor="ruecktrittAccept">
                            <a href={data.strapiImmoforms.ruecktrittsRechtPDF.url} target={"_blank"}>
                              Verzicht des Rücktritts-Rechts
                            </a>
                          </label>

                        </div>



                        <RcFormErrors touched={touched} errors={errors} className={"mb-2"} />


                        <RcButton onClick={handleSubmit} className={"d-inline-block mt-2 mb-4"}>
                          Zustimmen
                        </RcButton>

                      </form>
                    }
                  }
                </Formik>

                }

                {
                  sent &&
                    <div className={"mt-5"}>
                      <h3>Daten erfolgreich aktualisiert</h3>
                      <p>Vielen Dank! Weitere Informationen bzgl. Detail Expose etc. werden Ihnen per E-Mail zugeschickt.</p>
                    </div>
                }




        </div>
      </div>
    </div>

  </Layout>

};

export default ImmoanfragePage;


export const query = graphql`query ImmoInquiryPageQuery {

  strapiImmoforms {
    nebenkostenPDF {
      url
    }
    dsgvoPDF {
      url
    }
    ruecktrittsRechtPDF {
      url
    }
  }
  
}
`;
