import Modal from "react-modal";
import React, { useRef } from "react";

import * as styles from './rcLoadingModal.module.scss';

import CircleSVG from "../../icons/fontawesome/light/circle.svg";

Modal.setAppElement(`#___gatsby`);

const customStyles = {
  content : {
    top                   : '0',
    left                  : '0',
    right                 : '0',
    bottom                : '0',
    padding: '0',
    overflow: 'hidden',
    background: 'none'
  },

  overlay : {
    zIndex: 20000,
    backgroundColor: "rgba(255, 255, 255, .5)"
  }
};

const RcLoadingModal = ({ isOpen }) => {

  const targetRef = useRef();

  return <Modal ref={targetRef}
                isOpen={isOpen} style={customStyles}>
    <div className="d-flex flex-column text-black h-100 align-items-center justify-items-center mx-auto">
      <CircleSVG className={styles.loading} />
      Lade ...
    </div>
  </Modal>;

};

export default RcLoadingModal;
